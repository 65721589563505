<template>
  <main-container :icon="$route.meta.icon" :title="$route.meta.title">
    <emc-loader-progress :show="loading" />

    <v-row no-gutters>
      <v-col cols="12" sm="4" align-self="center">
        <v-treeview
            :active.sync="active"
            :items="collections"
            item-key="id"
            activatable
        >
          <template v-slot:prepend="{ item }">
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-btn v-if="item.children" @click.stop="setModel(item)" icon><v-icon>mdi-plus</v-icon></v-btn>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="8">
        <emc-base-card :icon="$route.meta.icon" :title="model.id ? `Atualização do menu: ${model.name}` : `Novo menu`">
          <template v-slot:tools v-if="model.id">
              <v-chip class="ma-1" color="grey" outlined>
                <v-avatar left>
                  <v-icon small>mdi-key</v-icon>
                </v-avatar>
                {{ model.id }}
              </v-chip>
          </template>
          <template v-slot:card-text>

              <v-text-field v-model="model.text" label="Nome"></v-text-field>
              <v-text-field v-model="model.description" label="Descrição"></v-text-field>
              <v-text-field v-model="model.to" label="URI"></v-text-field>
              <v-text-field v-model="model.icon" label="Icone"></v-text-field>
              <v-text-field v-model="model.menu_id" label="Menu pai"></v-text-field>
              <v-text-field v-model="model.menu_order" label="Ordem"></v-text-field>

              <v-row>
                <v-col cols="12" sm="4">
                  <v-switch v-model="model.is_admin" inset dense label="Menu Administrativo"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch v-model="model.is_internal" inset dense label="Menu interno"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch v-model="model.active" inset dense label="Menu ativo"></v-switch>
                </v-col>
              </v-row>

              <v-btn color="primary" class="mr-4" :loading="loadingSave" :disabled="loadingSave || !model.text" @click.stop="save()">Salvar</v-btn>
              <v-btn color="error" :loading="loadingDestroy" :disabled="loadingDestroy || !model.text" @click.stop="destroy()">Excluir</v-btn>
          </template>
        </emc-base-card>
      </v-col>
    </v-row>

  </main-container>
</template>

<script>
import { mapActions } from 'vuex'

export default{
  data: () => ({
    collections: [],
    active: [],
    loading: false,
    loadingSave: false,
    loadingDestroy: false,
    create: false,
    model: {},
    itemSelected: {}
  }),
  watch: {
    active (item) {

      if (!this.active.length) return undefined

      const id = item[0]
      let menu = this.collections.find(item => item.id === id)
      let children = {}

      /* procura o menu no children */
      if(menu === undefined) {
          this.collections.find(function (item) {
              if(item.children.length) {
                 children = item.children.find(ch => ch.id === id)
                if(children !== undefined) {
                  menu = children
                }
              }
          })
      }

      this.model = menu
    },
  },
  methods: {
    ...mapActions('menu', ['ActionFindMenus', 'ActionStoreMenu', 'ActionUpdateMenu', 'ActionDestroyMenu']),
    setModel(item) {
        this.itemSelected = item
        this.model = {
          menu_id: item.id
        }
    },
    getData() {
      this.active = []
      this.itemSelected = {}
      this.model = {}
      this.loading = true;
      let params = {
        column_order: 'menu_order',
        filterIsInternalAndAdmin: false,
      }

      this.ActionFindMenus(params)
          .then((res) => {
              this.collections = res.data
          })
          .finally(() => {
            this.loading = false;
          })
    },
    save() {

      if(this.model.id) {
        this.loadingSave = true
        this.ActionUpdateMenu(this.model)
          .then((r) => {
              this.model = {}
              this.getData()
          })
          .catch((e)  => {
              
          })
          .finally(() => {
            this.loadingSave = false
          })
      } else {
        this.ActionStoreMenu(this.model)
            .then((r) => {
              this.model = {}
              this.getData()
            })
            .catch((e)  => {
              
            })
            .finally(() => {
              this.loadingSave = false
            })
      }
    },
    destroy() {
      this.loadingDestroy = true
      this.ActionDestroyMenu({ id: this.model.id})
        .then((r) => {
          this.getData()
        })
        .catch((e) => {
          
        })
        .finally(() => {
          this.loadingDestroy = false
        })
    }
  },
  created() {
    this.getData();
  }

}

</script>
